// eslint-disable-next-line
import React, { useState } from 'react';

import Routes from './src-site/Routes';

function App() {
  return (
    <div>
      <Routes/>
    </div>
  );
}

export default App;
