// eslint-disable-next-line
import React, { useState } from 'react';
import './NodeStyle.css';
import { Link } from 'react-router-dom';
import NodeCurto from '../../../img/Tecnologias/Node/NodeCurto.png';
import Onda from '../../../img/Tecnologias/Node/ondaNode.png';
import Construcao from '../../../img/construcao.png';

import Head from '../../Head/Head';
import Footer from '../../Head/Footer';

import useWindowDimensions from '../../Outros/Dimensions';

function Salvar(){
  return(
      <div>
         <div className="C1">
          <div className="C1Texto"></div>
          <div className="DivVerde"></div>
        </div>

        <div className="C2">
          <div className="DivVerde2"></div>
          <div className="C2Texto"></div>
        </div>
      </div>
  )
}

function Meio(){
  const[NextBt, setNextBt] = useState('transparent')
  const[PreviousBt, setPreviousBt] = useState('transparent')

  const { height, width } = useWindowDimensions();

  if(width < 475){
    return(
    <div className= "MeioNode" style={{height: 500,}}>
      <img name="imagem-node" src={NodeCurto} style={{width: '100%', height: 'auto',}}/>
      <img name="imagem-node" src={Onda} style={{width: '100%', height: 30}}/>
      <div className="NodeC" style={{height: 300,}}>
        
        <h style={{fontSize: 20,}}>Esta página está em construção</h>
        <img name="imagem-node" src={Construcao} style={{width: '60%', height: 'auto',}}/>

      </div>

    </div>
  )}

  else if(width < 575){
    return(
    <div className= "MeioNode" style={{height: 600,}}>
      <img name="imagem-node" src={NodeCurto} style={{width: '100%', height: 'auto',}}/>
      <img name="imagem-node" src={Onda} style={{width: '100%', height: 50}}/>
      <div className="NodeC" style={{height: 400,}}>
        
        <h style={{fontSize: 25,}}>Esta página está em construção</h>
        <img name="imagem-node" src={Construcao} style={{width: '60%', height: 'auto',}}/>

      </div>

    </div>
  )}

  else if(width < 750){
    return(
    <div className= "MeioNode" style={{height: 700,}}>
      <img name="imagem-node" src={NodeCurto} style={{width: '100%', height: 'auto',}}/>
      <img name="imagem-node" src={Onda} style={{width: '100%', height: 65}}/>

      <div className="NodeC" style={{height: 500,}}>
        
        <h style={{fontSize: 30,}}>Esta página está em construção</h>
        <img name="imagem-node" src={Construcao} style={{width: '50%', height: 'auto',}}/>

      </div>

    </div>
  )}

  else if(width < 1000){
    return(
    <div className= "MeioNode" style={{height: 800,}}>
      <img name="imagem-node" src={NodeCurto} style={{width: '100%', height: 'auto',}}/>
      <img name="imagem-node" src={Onda} style={{width: '100%', height: 80}}/>

      <div className="NodeC" style={{height: 600,}}>
        
        <h style={{fontSize: 35,}}>Esta página está em construção</h>
        <img name="imagem-node" src={Construcao} style={{width: '40%', height: 'auto',}}/>

      </div>

    </div>
  )}

  else{
    return(
    <div className= "MeioNode" style={{height: 1100,}}>
      <img name="imagem-node" src={NodeCurto} style={{width: '100%', height: 'auto',}}/>
      <img name="imagem-node" src={Onda} style={{width: '100%', height: 100}}/>
      
      <div className="NodeC" style={{height: 800,}}>
        
        <h style={{fontSize: 40,}}>Esta página está em construção</h>
        <img name="imagem-node" src={Construcao} style={{width: '30%', height: 'auto',}}/>

      </div>

    </div>
  )}
}

function Node() {
  return (
    <div>
      <Head/>
      <Meio/>
      <Footer/>
    </div>
  );
}

export default Node;
