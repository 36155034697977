import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Home from './Home/Home';
import Projetos from './Projetos/Projetos';
  import Progenios from './Projetos/Progenios/Progenios';
import Tecnologias from './Programações/Programacoes';
  import Node from './Programações/Node/Node';
  import Npm from './Programações/Npm/Npm';
  import Python from './Programações/Python/Python';
import QuemSomos from './QuemSomos/QuemS';
import Contato from './Contato/Contato';


export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>

        <Route exact path="/" component={Home} />
        <Route exact path="/proj" component={Projetos} />
          <Route exact path="/proj/progenios" component={Progenios} />
        <Route exact path="/tec" component={Tecnologias} />
          <Route exact path="/tec/node" component={Node} />
          <Route exact path="/tec/npm" component={Npm} />
          <Route exact path="/tec/python" component={Python} />
        <Route exact path="/quem-somos" component={QuemSomos}/>
        <Route exact path="/contato" component={Contato}/>

      </Switch>
    </BrowserRouter>
  );
}
